import axios from "@/api/axios"; // 引用axios

export const byRegionId = (params) => {
    return axios({
        url: "/api/region/getResourceListByRegionId",
        method: "post",
        params: params,
    });
};

