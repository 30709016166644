<template>
  <div class="tree" id="tree"></div>
  <el-dialog title="提示" v-model="dialogVisible" width="80%" :before-close="handleClose">
    <basicTable
        :isPage="false"
        :tableData="tableData"
        :attrList="attrList"
    >
    </basicTable>
  </el-dialog>
</template>
<script>
import { getTreeData } from "../../api/system/region";
import {onMounted, reactive, ref, toRefs} from "vue";
import * as echarts from 'echarts';
import { byRegionId } from "../../api/topological/setup"
import { getEquipModel } from "../../api/basic/deviceModel";
import { getEquipType } from "../../api/basic/equipType";
export default {
  setup(){
    let dialogVisible = ref(false);
    let state = reactive({
      chartTree:null,
      equipType:[],
      equipModel:[],
      tableData:[],
      attrList:[
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "ipAddr",
          label: "IP地址",
          render: (row) => <div>{row.ipAddr}</div>,
        },
        {
          prop: "tude",
          label: "坐标",
          render: (row) => <div>{row.longitude},{row.latitude}</div>,
        },
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr}</div>,
        },
        {
          prop: "model",
          label: "设备名称",
          render: (row) => <div>{state.equipModel.filter(item=>item.code == row.model)[0].name}</div>,
        },
        {
          prop: "regionName",
          label: "区域名称",
          render: (row) => <div>{row.regionName}</div>,
        },
        {
          prop: "type",
          label: "设备类型",
          render: (row) => <div>{state.equipType.filter(item=>item.code == row.type)[0].name}</div>,
        },
        {
          prop: "workStatus",
          label: "工作状态",
          render: (row) => <div>{row.workStatus == 1 ? '空闲' : row.workStatus == 2 ? '工作' :  row.workStatus == 0 ? '离线' : row.workStatus == 9 ? '未知' : '故障'}</div>,
        },
        {
          prop: "image",
          label: "图片",
          render: (row) => <div>{state.equipType.filter(item=>item.code == row.type)[0].iconUrl}</div>,
        },
      ]
    })

    function initChartTree(data){
      state.chartTree = echarts.init(document.getElementById('tree'));
      let option = {
        series: [
          {
            type: 'tree',
            id: 0,
            name: 'tree1',
            data: data,
            left: "10%",
            top: "0%",
            edgeShape: "polyline",
            right: "50%",
            bottom: "5%",
            // edgeShape: 'polyline',
            // edgeForkPosition: '63%',
            initialTreeDepth: 1,
            lineStyle: {
              width: 2,
              color: "#0A6A61",
              curveness: 0
            },
            label: {
              position: 'left',
              color:'#FFFFFF',
              fontSize:24,
              backgroundColor:"#03534C",
              padding: [20, 45, 20, 45],
            },
            // symbol:require('../../assets/chart.png'),
            // symbolSize:[160,60],
            leaves: {
              label: {
                position: "inside",
                verticalAlign: 'middle',
              }
            },
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      };
      option && state.chartTree.setOption(option);
      var temp = state.chartTree;
      var container = document.getElementById('tree')
      state.chartTree.on('click',function(params){
        if(params.componentType === 'series'){
          if(!params.value){
            if(params.data.level == 6){
              byRegionId({id: params.data.id}).then(res=>{
                dialogVisible.value = true;
                state.tableData = res.data.data;
              })
            }
            var elesArr = Array.from(new Set(temp._chartsViews[0]._data._graphicEls));
            var currentHeight = 80 * (elesArr.length - 1) || 10;
            var newHeight = Math.max(currentHeight,870);
            container.style.height = newHeight + 'px';
            temp.resize()
          }
        }
      })
    }

    onMounted(()=>{
      getEquipType().then(res=>state.equipType = res.data.data);
      getEquipModel().then(res=>state.equipModel = res.data.data);
      getTreeData().then(res=>{
        initChartTree(res.data.data);
      });
    })
    return{
      dialogVisible,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
.tree{
  width: 100%;
  height: 870px;
}
</style>